import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CoreCommonModule } from '@core/common.module';
import { QRCodePreviewComponent } from './presentation/qr-code-preview/qr-code-preview.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

@NgModule({
  declarations: [
    QRCodePreviewComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    // NgxQRCodeModule,
  ],
  exports: [
    QRCodePreviewComponent,
  ],
})
export class QRCodePreviewModule { }
