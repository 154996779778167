import { Component, OnInit } from '@angular/core';
import { appKeys } from 'app/@core/values/app-keys';
@Component({
selector: 'app-block-template',
templateUrl: './block-template.component.html',
styleUrls: ['./block-template.component.scss']
})
export class BlockTemplateComponent {
  // ------------------------------- Public Fields -------------------------------

  public appKeys = appKeys;

  // ------------------------------- Private Fields -------------------------------

  // ------------------------------- Constructor -------------------------------

  constructor() {}

  // ------------------------------- Lifecycle Hooks -------------------------------

  // ------------------------------- Public Functions -------------------------------

  // ------------------------------- Private Functions -------------------------------
}
