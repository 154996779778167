import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { appKeys } from 'app/@core/values/app-keys';
import { AlertComponentState } from './ui/alert.state';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertComponentComponent implements OnInit {

  // ------------------------------- Public Fields -------------------------------

  @Input('data') public uiState: AlertComponentState = 
    new AlertComponentState();

  public appKeys = appKeys;
  
  // ------------------------------- Private Fields -------------------------------

  // ------------------------------- Constructor -------------------------------

  constructor() {}

  // ------------------------------- Lifecycle Hooks -------------------------------

  ngOnInit(): void {}

  // ------------------------------- Public Functions -------------------------------


  // ------------------------------- Private Functions -------------------------------

}
