import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';

import { SearchService } from 'app/layout/components/navbar/navbar-search/search.service';
import { layoutKeys } from 'app/layout/@core/values/layout-keys';
import { appKeys } from 'app/@core/values/app-keys';
import { NavbarSearchData } from './navbar-search.data';
import { NavbarSearchEvents } from './navbar-search.events';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar-search',
  templateUrl: './navbar-search.component.html'
})
export class NavbarSearchComponent implements OnInit, AfterViewInit {

  // ------------------------------- Public Fields -------------------------------

  public uiData: NavbarSearchData = new NavbarSearchData();

  public uiEvents: NavbarSearchEvents = new NavbarSearchEvents(
  );

  public layoutKeys = layoutKeys;
  public appKeys = appKeys;

  public searchText = '';
  public openSearchRef = false;
  public activeIndex = 0;

  // ------------------------------- Private Fields -------------------------------

  @ViewChild('openSearch') private _inputElement!: ElementRef;
  @ViewChild('modalSe') _modalElement!: ElementRef;

  @HostListener('keydown.escape') fn() {
    this.removeOverlay();
    this.openSearchRef = false;
    this.searchText = '';
  }

  @HostListener('document:click', ['$event']) clickout(event: any) {
    if (event.target.className === 'content-overlay') {
      this.removeOverlay();
      this.openSearchRef = false;
      this.searchText = '';
    }
  }

  // ------------------------------- Constructor -------------------------------

  constructor(
    @Inject(DOCUMENT) private document: any,
    public _searchService: SearchService,
    private _modalService: NgbModal,
  ) {

  }

  // ------------------------------- Lifecycle Hooks -------------------------------

  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

  }

  // ------------------------------- Public Functions -------------------------------

  public removeOverlay(): void {
    // this.document.querySelector('.app-content').classList.remove('show-overlay');
  }

  public toggleSearch(): void {
    // this.removeOverlay();
    this.openSearchRef = !this.openSearchRef;
    this.activeIndex = 0;
    setTimeout(() => {
      this._inputElement.nativeElement.focus();
    });

    if (this.openSearchRef === false) {
      // this.document.querySelector('.app-content').classList.remove('show-overlay');
      this.searchText = '';
    }
  }
}
