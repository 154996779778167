export const locale ={
    lang: 'ar',
    data: {
        NAVBAR:{
            LOGOALT: 'شعار الشركة',
            PROFILE_LOGO_ALT: 'صورة الحساب',
            ACCOUNT_SETTINGS: 'إعدادات الحساب',
            LOGOUT: 'تسجيل خروج'
        },

        NAVBAR_SEARCH: {
            SEARCH: 'بحث',
        }
    }
}