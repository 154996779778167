export const locale = {
  lang: 'ar',

  data: {
    USER_NAME: 'اسم المستخدم',
    COMPANY_MAIN_NAME: 'اسم الشركة الأساسي',
    COMPANY_SECOND_NAME: 'اسم الشركة التجاري',
    COMPANY_CATEGORY: 'تصنيف الشركة',
    AGENTS_DEFAULT_NUMBER: 'عدد الوكلاء الأفتراضي',
    BUSINESS_NUMBER: 'رقم السجل التجاري',
  },
};
