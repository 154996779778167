<li class="nav-item nav-search">

  <!-- Search icon -->
  <a class="nav-link nav-link-search" title="{{ appKeys.SEARCH_HERE | translate}}" (click)="toggleSearch()">
    <span [data-feather]="'search'" [class]="'ficon'"></span>
  </a>
  <!--/ Search icon -->

  <!-- Search -->
  <div class="search-input" [class.open]="openSearchRef === true">

    <!-- Search Input -->
    <div class="search-input-icon">
      <span [data-feather]="'search'"></span>
    </div>

    <input class="form-control input" type="text" placeholder="{{appKeys.SEARCH_HERE | translate}}" tabindex="-1" data-search="search"
      (keyup.enter)="uiEvents.onSearch($event)" [(ngModel)]="searchText" #openSearch />

    <button class="btn search-input-close p-0" (click)="toggleSearch()">
      <span [data-feather]="'x'"></span>
    </button>
    <!--/ Search Input -->

  </div>
  <!--/ Search -->
  
</li>