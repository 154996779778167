export const locale = {
  lang: 'ar',
  data: {
    MENU: {
      DASHBOARD: 'الرئيسية',
      PRODUCTS_MANAGEMENT: 'إدارة المنتجات',
      ORDERS_MANAGEMENT: 'إدارة الطلبات',
      AGENTS_MANAGEMENT: 'إدارة الوكلاء',
      CHATS: 'المحادثات',
      NOTIFICATIONS: 'الإشعارات',
      SETTINGS: 'إدارة اللوحة',
      STATS: 'الإحصائيات',
      PLANS: 'الباقات',
      LOGOUT: 'تسجيل الخروج',
    },
  },
};
