import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { appKeys } from '../values/app-keys';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DateUtilsService {

  public regex = /^\d{1,2}\/\d{1,2}\/\d{4}, \d{1,2}:\d{2}:\d{2} (AM|PM)$/;

  constructor(
    private _translate: TranslateService,
  ) {}

  public getToday(): Date {
    let date = new Date();
    let dateString = this.convertDateToISO(
      date.toString(),
    );
    return new Date(dateString);
  }

  public getYasterday(): Date {
    let date = new Date();
    date.setDate(date.getDate() - 1);
    let dateString = this.convertDateToISO(
      date.toString(),
    );
    return new Date(dateString);
  }

  public convertDateToISO(
    dateString: string,
  ): string {
    let dt = new Date(dateString);
    var tzoffset = dt.getTimezoneOffset() * 60000;
    var localISOTime = new Date(dt.getTime() - tzoffset).toISOString();
    return localISOTime;
  }

  public disableFlatInput(
    flatDateControl: any,
  ): void {
    flatDateControl.flatpickrElement.nativeElement.children[1].setAttribute('disabled', '');
  }

  public enableFlatInput(
    flatDateControl: any,
  ): void {
    flatDateControl.flatpickrElement.nativeElement.children[1].removeAttribute('disabled');
  }

  public showDateOnly(dateStr: string) {
    if (!dateStr) { return ''; }
    return formatDate(dateStr, 'dd/MM/yyyy', 'en-GB');
  }

  public getDateOrTime(dateStr: string, noTime: boolean = false) {
    if (!dateStr) {
      return "";
    }
    let dateTime;

    if(!this.regex.test(dateStr)) {
      let date = formatDate(dateStr, "dd/MM/yyyy", "en-GB");
      let time = formatDate(dateStr, "hh:mm a", "en-GB");
  
      dateTime = `${date} ${time}`;
    }
    else {
      dateTime = dateStr;
    }

    dateTime = 
      dateTime
        .replace("AM", this._translate.instant(appKeys.AM))
        .replace("PM", this._translate.instant(appKeys.PM))
        .replace("ص", this._translate.instant(appKeys.AM))
        .replace("م", this._translate.instant(appKeys.PM));

    return dateTime;
  }

  public getDateOrTimeMessage(
    dateStr: string,
    noTime: boolean = false,
    onlyTime: boolean = false,
  ) {
    if (!dateStr) {
      return "";
    }
    let date: string = "";
    let year = formatDate(dateStr, "yyyy", "en-GB");
    let month = formatDate(dateStr, "MM", "en-GB");
    let day = formatDate(dateStr, "dd", "en-GB");
    let time = formatDate(dateStr, "hh:mm a", "en-GB");

    let curDate = new Date();
    let curYear = formatDate(curDate, "yyyy", "en-GB");
    let curMonth = formatDate(curDate, "MM", "en-GB");
    let curDay = formatDate(curDate, "dd", "en-GB");

    time = 
      time
        .replace("AM", this._translate.instant(appKeys.AM))
        .replace("PM", this._translate.instant(appKeys.PM))
        .replace("pm", this._translate.instant(appKeys.PM))
        .replace("am", this._translate.instant(appKeys.AM))
        .replace("م", this._translate.instant(appKeys.PM))
        .replace("ص", this._translate.instant(appKeys.AM));

    if(onlyTime) {
      return time;
    }

    if (year == curYear) {
      if (month == curMonth) {
        if (day == curDay) {
          if (noTime) {
            return this._translate.instant(appKeys.TODAY);
          }
          return time;
        } else {
          date = formatDate(dateStr, "dd/MM", "en-GB");
        }
      } else {
        date = formatDate(dateStr, "dd/MM", "en-GB");
      }
    } else {
      date = formatDate(dateStr, "MM/yyyy", "en-GB");
    }
    
    if(noTime) {
      return date;
    }
    return `${date} ${time}`;
  }

  public isSameDayButNotSameMinute(
    dateTimeString1: string, 
    dateTimeString2: string,
  ): boolean {
    const dateTime1 = new Date(dateTimeString1);
    const dateTime2 = new Date(dateTimeString2);

    // Check if the dates have the same year, month, and day
    const sameDay = dateTime1.getFullYear() === dateTime2.getFullYear() &&
      dateTime1.getMonth() === dateTime2.getMonth() &&
      dateTime1.getDate() === dateTime2.getDate();
    
    // Check if the times are on the same day but not in the same minute
    const sameMinute = dateTime1.getMinutes() === dateTime2.getMinutes();
    
    return sameDay && !sameMinute;
  }

  public isSameDay(
    dateTimeString1: string, 
    dateTimeString2: string,
  ): boolean {
    const dateTime1 = new Date(dateTimeString1);
    const dateTime2 = new Date(dateTimeString2);

    // Check if the dates have the same year, month, and day
    const sameDay = dateTime1.getFullYear() === dateTime2.getFullYear() &&
      dateTime1.getMonth() === dateTime2.getMonth() &&
      dateTime1.getDate() === dateTime2.getDate();
    
    return sameDay;
  }

  public formatLongDate(
    dateString: string,
  ): {
    year: string,
    month: string,
    day: string,
  } {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });

    return {
      year: year.toString(),
      month: month.toString(),
      day: day.toString(),
    };
  }
}