import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DialogsComponentState } from '../../presentation/dialogs/ui/dialogs.state';

@Injectable({
  providedIn: 'root'
})
export class DialogsService {

  // ------------------------------- Public Fields -------------------------------

  public uiData!: DialogsComponentState;

  // ------------------------------- Private Fields -------------------------------


  // ------------------------------- Constructor -------------------------------

  constructor(
    private _modalService: NgbModal,
    private _translate: TranslateService,
  ) {
  }

  // ------------------------------- interface Resolve -------------------------------


  // ------------------------------- Public Functions -------------------------------

  public danger(
    title: string = this._translate.instant('CONFIRMATION'),
    message: string = '',
    primaryButtonText: string = this._translate.instant('OK'),
  ): Promise<any> {
    return this._showDialog(
      title,
      message,
      primaryButtonText,
      'modal-danger',
      'btn-danger',
    );
  }

	public info(
    title: string = this._translate.instant('CONFIRMATION'),
    message: string = '',
    primaryButtonText: string = this._translate.instant('OK'),
  ): Promise<any> {
    return this._showDialog(
      title,
      message,
      primaryButtonText,
      'modal-info',
      'btn-info',
    );
  }
  
	public warning(
    title: string = this._translate.instant('CONFIRMATION'),
    message: string = '',
    primaryButtonText: string = this._translate.instant('OK'),
  ): Promise<any> {
    return this._showDialog(
      title,
      message,
      primaryButtonText,
      'modal-warning',
      'btn-warning',
    );
  }
  
	public primary(
    title: string = this._translate.instant('CONFIRMATION'),
    message: string = '',
    primaryButtonText: string = this._translate.instant('OK'),
  ): Promise<any> {
    return this._showDialog(
      title,
      message,
      primaryButtonText,
      'modal-primary',
      'btn-primary',
    );
  }

  // ------------------------------- Private Functions -------------------------------

  private _showDialog(
    title: string,
    message: string,
    primaryButtonText: string,
    classType: string,
    buttonClassType: string,
  ): Promise<any> {
    this.uiData.title = title;
    this.uiData.message = message;
    this.uiData.primaryButtonText = primaryButtonText;
    this.uiData.buttonClassType = buttonClassType;
    this.uiData.reportType = '';

    this.uiData.modalRef = this._modalService.open(
      this.uiData.modalElement,
      {
        centered: false,
        windowClass: `modal ${classType}`,
      },
    );

    return this.uiData.modalRef.result;
  }
}
