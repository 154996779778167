import { AppService } from "app/app.service";
import { QRCodePreviewState } from "./qr-code-preview.state";
import { QRCodePreviewService } from "../../../@core/utils/qr-code-preview.service";


export class QRCodePreviewEvents {

  // ------------------------------- Public Fields -------------------------------

  // ------------------------------- Private Fields -------------------------------

  // ------------------------------- Constructor -------------------------------

  constructor(
    private _uiState: QRCodePreviewState,
    private _appService: AppService,
    private _QRCodePreviewService: QRCodePreviewService,
  ) {
  }

  // ------------------------------- Public Functions -------------------------------


  public toggleOpen(
    isOpen: boolean,
  ): void {
    if (isOpen) {
      this._appService.isQRCodePreviewOpen = true;
    }
    else {
      this._appService.isQRCodePreviewOpen = false;
    }
  }

  public close(): void {
    this._appService.isQRCodePreviewOpen = false;
    this._QRCodePreviewService.previewQRCodeChanged.next(false);
  }

  public onModalContentClick(event: any): void {
    event.stopPropagation();
  }

  // ------------------------------- Private Functions -------------------------------



}

