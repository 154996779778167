import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CoreDirectivesModule } from '../../directives/directives';
import { CoreSidebarModule } from '../core-sidebar/core-sidebar.module';

import { CoreThemeCustomizerComponent } from './theme-customizer.component';
import { NgScrollbarModule } from 'ngx-scrollbar';


@NgModule({
  declarations: [CoreThemeCustomizerComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CoreDirectivesModule,
    NgScrollbarModule,
    CoreSidebarModule
  ],
  providers: [
  ],
  exports: [CoreThemeCustomizerComponent]
})
export class CoreThemeCustomizerModule {}
