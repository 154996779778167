<!-- Image Preview -->
<ng-container *ngIf="appService.isQRCodePreviewOpen === true">

  <div class="modal d-block" id="qr-code-preview" style="top:0 !important; overflow-y: auto;" (click)="uiEvents.close()">

    <div class="modal-dialog" style="max-width: 442px !important;">
    
      <div class="modal-content p-0" (click)="uiEvents.onModalContentClick($event)">
    
        <div class="modal-header">
    
          <h5 class="modal-title"></h5>
    
          <div class="modal-actions">
            
            <a class="text-body" data-dismiss="modal" aria-label="Close" (click)="uiEvents.close()">
              <i [data-feather]="'x'"></i>
            </a>
          
          </div>
        
        </div>
        
        <div class="modal-body flex-grow-2 p-0">

          <!-- <ngx-qrcode class="d-flex justify-content-center m-1" [width]="400" [value]="uiState.value"></ngx-qrcode> -->

        </div>
      
      </div>
    
    </div>
  
  </div>

</ng-container>

<div class="modal-backdrop fade show" *ngIf="appService.isQRCodePreviewOpen === true"></div>
<!--/ Image Preview -->
