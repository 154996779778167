import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { AppService } from 'app/app.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaPreviewService } from '../../../media-preview/@core/utils/media-preview.service';
import { FilePreviewService } from '../../@core/utils/file-preview.service';
import { FilePreviewEvents } from './ui/file-preview.events';
import { FilePreviewState } from './ui/file-preview.state';
import { AuthService } from 'app/features/auth/@core/utils/auth.service';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit, OnDestroy {

  // ------------------------------- Public Fields -------------------------------
  
  public uiState: FilePreviewState = new FilePreviewState();  

  public uiEvents: FilePreviewEvents = new FilePreviewEvents(
    this.uiState,
    this.appService,
    this._filePreviewService,
    );

  // ------------------------------- Private Fields -------------------------------


  // ------------------------------- Constructor -------------------------------

  constructor(
    private _filePreviewService: FilePreviewService,
    private _authService: AuthService,
    public appService: AppService,
    public mediaPreviewService: MediaPreviewService
  ) {
    this.uiState._unsubscribeAll = new Subject();
  }

  // ------------------------------- Lifecycle Hooks -------------------------------

  ngOnInit(): void {
    this
      ._filePreviewService
      .previewFileChanged
      .pipe(
        takeUntil(
          this.uiState._unsubscribeAll,
        )
      ).subscribe(
        response => {
          this.uiState.fileSrc = 
            this
            ._filePreviewService
            .fileSrc;
          
          this
            .uiEvents
            .toggleOpen(
              response,
            );
    });
  }

  ngOnDestroy(): void{
    // Unsubscribe from all subscriptions
    this.uiState._unsubscribeAll.next(null);
    this.uiState._unsubscribeAll.complete();
  }

  // ------------------------------- Public Functions -------------------------------
  

  // ------------------------------- Private Functions -------------------------------
  
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.appService.isFilePreviewOpen){
      event.preventDefault();
      this.uiEvents.toggleOpen(false);
      this._filePreviewService.previewFileChanged.next(false);
    }
  }
}
