import { AppService } from "app/app.service";
import { MediaPreviewState } from "./media-preview.state";
import { MediaPreviewService } from "../../../@core/utils/media-preview.service";


export class MediaPreviewEvents {

  // ------------------------------- Public Fields -------------------------------


  // ------------------------------- Private Fields -------------------------------


  // ------------------------------- Constructor -------------------------------

  constructor(
    private _uiState: MediaPreviewState,
    private _appService: AppService,
    private _mediaPreviewService: MediaPreviewService,
  ) {
  }

  // ------------------------------- Public Functions -------------------------------

  public toggleOpen(
    isOpen: boolean,
  ): void {
    if (isOpen) {
      this._appService.isMediaPreviewOpen = true;
    }
    else {
      this._appService.isMediaPreviewOpen = false;
    }
  }

  public close(): void {
    this._appService.isMediaPreviewOpen = false;
    this._mediaPreviewService.previewImageChanged.next(false);
  }

  public onModalContentClick(event: any): void {
    event.stopPropagation();
  }


  // ------------------------------- Private Functions -------------------------------



}

