import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'durationDays'
})
export class DurationDaysPipe implements PipeTransform {
  transform(minutes: number): {
    days: number,
    hours: number,
    minutes: number,
  } {
    let days = 0;
    let hours = 0;
    let remainingMinutes = 0;

    if(minutes >= 1440) {
      days = Math.floor(minutes / 1440);
      minutes = minutes - (days * 1440);
    }

    if(minutes >= 60) {
      hours = Math.floor(minutes / 60);
      minutes = minutes - (hours * 60);
    }

    remainingMinutes = minutes;

    return {
      days: days,
      hours: hours,
      minutes: remainingMinutes,
    };
  }
}