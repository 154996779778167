import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QRCodePreviewService {

  // ------------------------------- Public Fields -------------------------------

  public previewQRCodeChanged: BehaviorSubject<boolean>;
  public value: string = '';
  public previewQRCodeRef!: boolean;
  public isQRCodePreview: boolean = true;

  // ------------------------------- Private Fields -------------------------------


  // ------------------------------- Constructor -------------------------------

  constructor(

  ) {
    this.previewQRCodeChanged = new BehaviorSubject(false);
  }

  // ------------------------------- interface Resolve -------------------------------


  // ------------------------------- Public Functions -------------------------------

  public previewQRCode(
    value: any,
  ): void {
    this.previewQRCodeRef = value;
    this.previewQRCodeChanged.next(this.previewQRCodeRef);
  }

  // ------------------------------- Private Functions -------------------------------

}
