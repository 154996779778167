<!-- Image Preview -->
<ng-container *ngIf="appService.isMediaPreviewOpen === true">

  <div class="modal d-block" id="image-preview" style="top:0 !important; overflow-y: auto;" (click)="uiEvents.close()">

    <div class="modal-dialog">
    
      <div class="modal-content p-0" (click)="uiEvents.onModalContentClick($event)">
    
        <div class="modal-header">
    
          <h5 class="modal-title"></h5>
    
          <div class="modal-actions">
            
            <a class="text-body" data-dismiss="modal" aria-label="Close" (click)="uiEvents.close()">
              <i [data-feather]="'x'"></i>
            </a>
          
          </div>
        
        </div>
        
        <div class="modal-body flex-grow-1 p-0">

          <img alt="" [src]="uiState.mediaSrc" class="w-100" *ngIf="uiState.isImagePreview">

          <video height="300" width="100%" controls *ngIf="!uiState.isImagePreview">
            <source [src]="mediaPreviewService.getProtectedUrl(uiState.mediaSrc)" type="video/mp4">
          </video>

        </div>
      
      </div>
    
    </div>
  
  </div>

</ng-container>

<div class="modal-backdrop fade show" *ngIf="appService.isMediaPreviewOpen === true"></div>
<!--/ Image Preview -->
