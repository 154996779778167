export const locale = {
  lang: 'ar',
  data: {
    ACCOUNTS: {
      GENERAL: 'عام',
      ACCOUNT_PHOTO: 'صورة الحساب',
      PHONE_NUMBER: 'رقم الجوال',
      NAME: 'الاسم',
      EMAIL: 'البريد الإلكتروني',
      EMAIL_NOT_CONFIRMED: 'لم يتم تأكيد البريد الإلكتروني. يرجى التأكد من استلامك لرسالة التأكيد والذهاب إلى صفحة ',
      RESEND: 'إعادة إرسال',
      CHANGE_PASSWORD: 'تغيير كلمة المرور',
      OLD_PASSWORD: 'كلمة المرور القديمة',
      NEW_PASSWORD: 'كلمة المرور الجديدة',
      PASSWORD_CONFIRM: 'تأكيد كلمة المرور',
      INFORMATION: 'معلومات شخصية',
      BRIEF: 'نبذة',
      BRIEF_PLACEHOLDER: 'يمكنك كتابة نبذة عنك',
      BIRTH_DATE: 'تاريخ الميلاد',
      COUNTRY: 'البلد',
      SYRIA: 'سوريا',
      LEBANON: 'لبنان',
      EGYPT: 'مصر',
      IRAQ: 'العراق',
      WEBSITE: 'الموقع الإلكتروني',
      TITLE: 'إعدادات الحساب',
      HOME: 'الرئيسية',
      PASSWORD_REQUIRED: '- كلمة المرور مطلوبة',
      PASSWORD_SHORT: '- كلمة المرور يجب ألا يقل عدد المحارف عن 6',
      CONFIRM_NOT_SAME: '- كلمة المرور وتأكيدها غير متطابقين',
      CHOSE_COUNTRY: 'يرجى اختيار البلد',
      NICKNAME_REQUIRED: '- حقل الاسم مطلوب',
      PHONE_NUMBER_REQUIRED: '- حقل الجوال مطلوب',
      EMAIL_REQUIRED: '- حقل البريد مطلوب',
      OLD_PASSWORD_REQUIRED: '- كلمة المرور القديمة مطلوبة',
      PASSWORD_CONFIRM_REQUIRED: '- تأكيد كلمة المرور مطلوب',
      PHONE_NUMBER_OR_EMAIL_REQUIRED: '- البريد الألكتروني او رقم الهاتف مطلوب.',
      WEBSITE_INVALID: '- يرجى إدخال موقع إلكتروني صحيح',
      NEW_PASSWORD_REQUIRED: '- كلمة المرور الجديدة مطلوبة',
      CONFIRM_EMAIL_PAGE: 'تأكيد البريد',
      EMAIL_EXPLAIN: 'في حال تغيير البريد الإلكتروني، ستصلك رسالة على البريد لتأكيده',
      PHONE_EXPLAIN: 'في حال تغيير رقم الجوال، ستصلك رسالة على الجوال لتأكيده',
      ENABLE_TWO_FACTOR: 'التحقق بخطوتين',
      CONFIRM_PASSWORD_REQUIRED: '- تأكيد كلمة المرور مطلوب.',
      PHONE_NUMBER_NOT_CONFIRMED: 'لم يتم تأكيد رقم الجوال. يرجى الذهاب إلى صفحة ',
      PASSWORD_NOT_VALID: '- كلمة المرور يجب ان تحوي ع الأقل على حرف صغير و حرف كبير و رقم و رمز.',
      CONFIRM_PHONE_NUMBER_PAGE: 'تأكيد الجوال',
      CHANGE_PHONE_TITLE: 'تغيير رقم الجوال',
      CHANGE_PHONE_ACTIVATION_CODE: 'رمز التأكد',
      COUNTRY_CODE_REQUIRED: '- رمز البلد مطلوب.',
      SUCCESS_CHANGE_PHONE_MESSAGE: 'تم تغيير رقم الجوال بنجاح',
      CHANGE_EMAIL_TITLE: 'تغيير البريد الإلكتروني',
      EMAIL_CHANGE_PLACEHOLDER: 'يرجى ادخال بريدك الإلكتروني الجديد',
      SUCCESS_CHANGE_EMAIL_MESSAGE: 'تم تغيير البريد الإلكتروني بنجاح',
      EXPLAIN_PHONE: 'في حال تغيير رقم الجوال، ستصلك رسالة على الجوال لتأكيده',
      EXPLAIN_EMAIL: 'في حال تغيير البريد الإلكتروني، ستصلك رسالة على البريد لتأكيده',
    },
    LOGIN: {
      WELCOME: 'مرحباً بك!',
      LOGIN_TEXT: 'يرجى إدخال معلومات حسابك لتتمكن من الدخول إلى اللوحة',
      EMAIL: 'البريد الإلكتروني',
      EMAIL_IS_REQUIRED: '- البريد مطلوب',
      PASSWORD: 'كلمة المرور',
      FORGOT_PASSWORD: 'نسيت كلمة المرور؟',
      PASSWORD_IS_REQUIRED: '- كلمة المرور مطلوبة',
      LOGIN: 'تسجيل الدخول',
      SUCCESS_LOGIN_MESSAGE: 'تم تسجيل دخولك بنجاح. ابدأ مشوارك 🎉',
      SUCCESS_LOGIN_TITLE: '👋 أهلاً بك ',
      USE_EMAIL: 'البريد الإلكتروني',
      USE_PHONE: 'رقم الجوال',
      QR_SCAN: '- مسح رمز QR بواسطة Google Authenticator',
      CHECK: 'تحقق',
      TFA_CODE_IS_REQUIRED: '- رمزالتحقق مطلوب',
      QR_CODE: 'رمز التحقق',
      CONFIRM_PHONE: 'تأكيد الهاتف',
      RESET2FA: 'فقدت الوصول؟',
      CONFIRM_EMAIL: 'تأكيد البريد',
      RECAPTCHA_UNDEFINED: '- فشل التحقق من أنك لست روبوت:', // this line should be with next two lines
      CHECK_YOUR_CONNECTED: '- تحقق من جودة اتصالك',
      CONNECTION_SECURE: '- تأكد أن اتصالك محمي',
      RELOAD_PAGE: '- أعد تحميل الصفحة',
      QR_KEY: '- يمكنك استخدام المفتاح',
      OR: 'أو',
      THE_ACCOUNT: 'الحساب',
      DOWNLOAD_GOOGLE_AUTH: 'حمل تطبيق Google Authenticator',
      DOWNLOAD_PLAY_STORE_ALT:'احصل عليه من Google Play',
      DOWNLOAD_APP_STORE_ALT:'احصل عليه من App Store',
    },
    FORGOT_PASSWORD: {
      FORGOT: 'نسيت كلمة المرور؟',
      FORGOT_TEXT: 'يرجى إدخال البريد الإلكتروني أو رقم جوالك لاستعادة كلمة المرور',
      EMAIL: 'البريد الإلكتروني',
      EMAIL_IS_REQUIRED: 'البريد مطلوب',
      SEND: 'إرسال',
      LOGIN: 'تسجيل الدخول',
    },
    RESET_PASSWORD: {
      RESET_PASSWORD: 'استعادة كلمة المرور',
      RESET_TEXT: 'يرجى إدخال كلمة سر جديدة',
      RESET_CODE: 'رمز الاستعادة',
      NEW_PASSWORD: 'كلمة مرور جديدة',
      FIELD_IS_REQUIRED: 'هذا الحقل مطلوب',
      CONFIRM_PASSWORD: 'تأكيد كلمة المرور',
      SAVE_NEW_PASSWORD: 'حفظ كلمة المرور',
      LOGIN: 'تسجيل الدخول',
      SUCCESS_LINK_SENT: 'تم إرسال رمز الاستعادة إلى بريدك الإلكتروني',
      SUCCESS_RESET_MESSAGE: 'تم تغيير كلمة المرور بنجاح',
      RESET_CODE_REQUIRED: '- رمز الاستعادة مطلوب',
    },
    CONFIRM_EMAIL: {
      CONFIRM_EMAIL_WELCOME: 'تأكيد البريد الإلكتروني',
      CONFIRM_EMAIL_TEXT: 'يرجى إدخال الرمز المستلم على بريدك الإلكتروني',
      SUCCESS_CONFIRM_EMAIL_MESSAGE: 'تم تأكيد بريدك الإلكتروني بنجاح',
      SUCCESS_CONFIRM_TITLE: 'تأكيد البريد الإلكتروني',
      EMAIL_IS_EMPTY: 'لا يمكن أن يكون البريد الإلكتروني غير محدد',
      PHONE_IS_EMPTY: 'لا يمكن أن يكون رقم الجوال غير محدد',
    },
    REGISTER: {
      REGISTER_TEXT: 'اجعل إدارة حساباتك سهلة وممتعة',
      SIGN_UP: 'تسجيل',
      ALREADY_HAVE_ACCOUNT: 'هل لديك حساب ؟',
      PASSWORD_NO_SAME: '- كلمة المرور غير متطابقة',
    },
    CONFIRM_PHONE: {
      ACTIVATION_CODE: 'كود التفعيل',
      ACTIVATION_CODE_REQUIRED: '- كود التفعيل مطلوب',
      WELCOME_CONFIRM_PHONE: 'تفعيل الحساب',
      CONFIRM_PHONE_TEXT: 'تم إرسال كود إلى رقم جوالك يرجى ادخال هذا الكود ',
      CONFIRM: 'تأكيد',
      CONFIRM_COUNTER_TEXT: 'يمكنك إعادة الإرسال خلال',
      SUCCESS_CONFIRM_PHONE_MESSAGE: 'تم تأكيد رقم الجوال بنجاح',
    },
    RESET_2FA: {
      RESET_2FA: 'إعادة ضبط رمز الوصول',
      RESET_2FA_WAIT: 'يرجى الانتظار ريثما يتم إرسال رمز إعادة ضبط Google Authenticator',
      ENTER_RESET_2FA: 'يرجى إدخال الرمز',
    }
  }
}