import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { CoreCommonModule } from "@core/common.module";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { TranslateModule } from "@ngx-translate/core";

import { AlertComponentComponent } from "./alert-component/presentation/alert/alert.component";
import { BlockTemplateComponent } from "./block-template/presentation/block-template/block-template.component";
import { CountryCodeModule } from "./country-code/country-code.module";
import { DialogsModule } from "./dialog/dialogs.module";
import { FilePreviewModule } from "./file-preview/file-preview.module";
import { MediaPreviewModule } from "./media-preview/media-preview.module";
import { QRCodePreviewModule } from "./qr-code-preview/qr-code-preview.module";

@NgModule({
  declarations: [
    AlertComponentComponent,
    BlockTemplateComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    NgbModule,
    TranslateModule.forChild(),
    FilePreviewModule,
    MediaPreviewModule,
    DialogsModule,
    QRCodePreviewModule,
  ],
  providers: [],
  exports: [
    AlertComponentComponent,
    BlockTemplateComponent,
    MediaPreviewModule,
    FilePreviewModule,
    DialogsModule,
    QRCodePreviewModule,
    CountryCodeModule,
  ],
})
export class ComponentsModule {}
