import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { DialogsComponentState } from './ui/dialogs.state';
import { DialogsEvents } from './ui/dialogs.events';
import { DialogsService } from '../../@core/utils/dialogs.service';
import { TranslateService } from '@ngx-translate/core';
import { appKeys } from 'app/@core/values/app-keys';
import { DateUtilsService } from 'app/@core/utils/date-utils.service';
import { DomSanitizer } from '@angular/platform-browser';
import { AppUtilsService } from 'app/@core/utils/app-utils.service';


@Component({
  selector: 'app-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DialogsComponent implements OnInit, AfterViewInit, OnDestroy {

  // ------------------------------- Public Fields -------------------------------

  @ViewChild('modalElement') modalElement!: TemplateRef<any>;
  @ViewChild('submit', { static: true })
  submit!: ElementRef<HTMLButtonElement>;

  public uiState: DialogsComponentState = new DialogsComponentState();

  public appKeys: any = appKeys;

  public uiEvents: DialogsEvents = new DialogsEvents(
    this.uiState,
    this._translate,
  );

  public dateUtilsService = this._dateUtilsService;
  public appUtilsService = this._appUtilsService;
  // ------------------------------- Private Fields -------------------------------


  // ------------------------------- Constructor -------------------------------

  constructor(
    private _dialogsService: DialogsService,
    private _translate: TranslateService,
    private _dateUtilsService: DateUtilsService,
    private _appUtilsService: AppUtilsService
  ) {
  }

  // ------------------------------- Lifecycle Hooks -------------------------------

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.uiState.modalElement = this.modalElement;
    this._dialogsService.uiData = this.uiState;
    if(this.submit) {
      this.submit.nativeElement.focus();
    }
  }

  ngOnDestroy(): void{}

  // ------------------------------- Public Functions -------------------------------


  // ------------------------------- Private Functions -------------------------------

}
