<!-- vertical-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'vertical'">
  <vertical-layout></vertical-layout>
</ng-container>
<!-- / vertical-layout -->

<!-- horizontal-layout -->
<ng-container *ngIf="coreConfig.layout.type === 'horizontal'">
  <horizontal-layout></horizontal-layout>
</ng-container>
<!-- / horizontal-layout -->

<app-media-preview></app-media-preview>

<app-file-preview></app-file-preview>

<app-qr-code-preview></app-qr-code-preview>

<app-dialogs></app-dialogs>