import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { animate, AnimationBuilder, AnimationPlayer, style } from '@angular/animations';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { appKeys } from '../../app/@core/values/app-keys';

@Injectable({
  providedIn: 'root'
})
export class CoreLoadingScreenService {
  loadingScreenEl!: HTMLElement;
  loadingScreenText!: HTMLElement | null;
  animationPlayer!: AnimationPlayer;
  isLocked: boolean = false;

  /**
   * Constructor
   *
   * @param _document
   * @param {Router} _router
   * @param {AnimationBuilder} _animationBuilder
   */
  constructor(
    @Inject(DOCUMENT) private _document: any,
    private _router: Router,
    private _animationBuilder: AnimationBuilder,
    private _translate: TranslateService,
  ) {
    // Initialize
    this._init();
  }

  // Private methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Initialize
   *
   * @private
   */
  private _init(): void {
    // Get the loading screen element
    this.loadingScreenEl = this._document.body.querySelector('#loading-bg');
    this.loadingScreenText = this.loadingScreenEl.querySelector('#loading-text');

    // If loading screen element
    if (this.loadingScreenEl) {
      this._router.events
        .subscribe(
          (event) => {
          if ((event instanceof NavigationStart) && !this.isLocked) {
            setTimeout(
              () => {
                this.show();
              }, 
              0,
            );
          } 
          else if (event instanceof NavigationEnd) {
            setTimeout(
              () => {
                this.hide();
              }, 
              0,
            );
          } 
          // Set loading state to false in both of the below events to hide the spinner in case a request fails
          else if (event instanceof NavigationCancel) {
            setTimeout(
              () => {
                this.hide();
              }, 
              0,
            );
          }
          else if (event instanceof NavigationError) {
            setTimeout(
              () => {
                this.hide();
              }, 
              0,
            );
          }
        });
    }
  }

  // Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Show the loading screen
   */
  show(loadingText: string = ''): void {
    this.animationPlayer = this._animationBuilder
      .build([
        style({
          opacity: '0',
          zIndex: '99999'
        }),
        animate('250ms ease', style({ opacity: '1' }))
      ])
      .create(this.loadingScreenEl);

    if (!loadingText) {
      loadingText = this._translate.instant(appKeys.PLEASE_WAIT);
    }
    if(this.loadingScreenText)
      this.loadingScreenText.innerHTML = loadingText;

    setTimeout(() => {
      this.loadingScreenEl.classList.remove('d-none');
      this.animationPlayer.play();
    }, 0);
  }

  /**
   * Hide the loading screen
   */
  hide(): void {
    // return;
    this.animationPlayer = this._animationBuilder
      .build([
        style({ opacity: '1' }),
        animate(
          '250ms ease',
          style({
            opacity: '0',
            zIndex: '-10'
          })
        )
      ])
      .create(this.loadingScreenEl);

    setTimeout(() => {
      this.loadingScreenEl.classList.add('d-none');
      this.animationPlayer.play();
    }, 0);
  }
}