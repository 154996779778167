import { TemplateRef } from "@angular/core";
import { faCalendar, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

export class DialogsComponentState {
// ------------------------------- Public Fields -------------------------------

public faCalendar = faCalendar;
public faDollarSign = faDollarSign;

public modalRef!: NgbModalRef;

public modalElement!: TemplateRef<any>;

public printId: string = '';
public pageSize: string = '';

public groupName: string = '';

public totalPays: number = 0;

public reportType: string = '';

public title: string = "";
public userType: string = "";
public message: string = "";
public primaryButtonText: string = "";
public buttonClassType: string = "";
public name: string = "";

// ------------------------------- Constructor -------------------------------

constructor() {}

// ------------------------------- Public Functions -------------------------------
}
