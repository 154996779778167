import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'preserveFormatting'
})
export class PreserveFormattingPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    return value.replace(/\n/g, '<br>')
      .replace(/\t/g, '&nbsp;&nbsp;&nbsp;&nbsp;')
      .replace(/ /g, '&nbsp;');
  }
}