import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";

export class NavbarSearchData {

    // ------------------------------- Public Fields -------------------------------

    public document: any;
    public modalRef!: NgbModalRef;
    public modalElement: any;
    
    // ------------------------------- Private Fields -------------------------------

    // ------------------------------- Constructor -------------------------------

    constructor() {

    }

    // ------------------------------- Public Functions -------------------------------

    // ------------------------------- Private Functions -------------------------------
}
