import { NgModule } from '@angular/core';

import { ThousandsSeparatorDirective } from './thousands-separator/thousands-separator.directive';
import { NgxResizeWatcherDirective } from './ngx-resize-watcher/ngx-resize-watcher.directive';
import { AutoResizeTextareaDirective } from './auto-resize-textarea/auto-resize-textarea.directive';
import { RippleEffectDirective } from './core-ripple-effect/core-ripple-effect.directive';
import { FeatherIconDirective } from './core-feather-icons/core-feather-icons';
import { RepeatElementDirective } from './repeat-element/repeat-element.directive';

@NgModule({
  declarations: [
    RippleEffectDirective,
    FeatherIconDirective,
    ThousandsSeparatorDirective,
    NgxResizeWatcherDirective,
    AutoResizeTextareaDirective,
    RepeatElementDirective,
  ],
  imports: [
  ],
  exports: [
    RippleEffectDirective,
    FeatherIconDirective,
    ThousandsSeparatorDirective,
    AutoResizeTextareaDirective,
    NgxResizeWatcherDirective,
    RepeatElementDirective,
  ]
})
export class CoreDirectivesModule { }
