import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Directive({
  selector: '[appNgxResizeWatcher]'
})
export class NgxResizeWatcherDirective implements AfterViewChecked, AfterViewInit {

  @Input() appNgxResizeWatcher!: DatatableComponent;

  private _tableWrapper!: ElementRef;
  private _dataTable!: DatatableComponent;
  private _currentComponentWidth: any;

  constructor(
    private el: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
  ) { 
    
  }

  ngAfterViewInit(): void {
    this._tableWrapper = this.el;
    this._dataTable = this.appNgxResizeWatcher;
  }

  ngAfterViewChecked(): void {
    if (this._dataTable && this._dataTable.recalculate && 
      (this._tableWrapper.nativeElement.clientWidth !== this._currentComponentWidth)) {
      this._currentComponentWidth = this._tableWrapper.nativeElement.clientWidth;
      this._dataTable.recalculate();
      this._changeDetectorRef.detectChanges();
    }

    let tableInfo: DOMRect = this._tableWrapper.nativeElement.getBoundingClientRect();
    var space = document.body.clientHeight - this._tableWrapper.nativeElement.offsetTop - this._tableWrapper.nativeElement.offsetHeight
    // console.log(document.body.clientHeight)
    // console.log(window.outerHeight)
    // console.log(document.body.clientHeight)
    
    // console.log(document.getElementsByClassName('main-menu')[0].clientHeight)
    // console.log(tableInfo.bottom)
    // console.log(document.body.clientHeight-tableInfo.bottom)
    // console.log(this._tableWrapper.nativeElement.offsetTop)
    // console.log(this._tableWrapper.nativeElement.offsetHeight)
    // console.log(space)
    // tableInfo.height += document.body.clientHeight-tableInfo.bottom ;
    // this._tableWrapper.nativeElement.style.border = '1px solid red';
  }

}
