import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaPreviewComponent } from 'app/components/media-preview/presentation/media-preview/media-preview.component';

import { CoreCommonModule } from '@core/common.module';

@NgModule({
  declarations: [
    MediaPreviewComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
  ],
  exports: [
    MediaPreviewComponent,
  ],
})
export class MediaPreviewModule { }
