<!-- Menu header -->
<div class="navbar-header pt-2" style="height: 180px;">
  @if (currentUser) {
    @if (currentUser.company) {
    <div class="d-flex justify-content-center align-items-center pr-1 flex-column ">
      <div style="width: 100px; height: 100px; border-radius: 50%; border: 1px solid white;" class="d-flex justify-content-center align-items-center ">
          <div style="width: 90px; height: 90px; border-radius: 50%;" class="bg-secondary d-flex justify-content-center align-items-center ">
            @if (currentUser.company.avatar) {
              <img style="
                width: 90px;
                height: 90px;
                object-fit: contain;
                border-radius: 50%;" [src]="currentUser.company.avatar.url" alt="">
            }
            @else {
              <fa-icon class="text-primary " style="font-size: 20px;" [icon]="faCamera"></fa-icon>
            }
          </div>
        </div>
        <h3 class="text-white mt-1 mb-0 text-center">{{currentUser.company.name}}</h3>
      </div>
    }
  }
  <ul class="nav navbar-nav flex-row">
    <li class="nav-item mr-auto">
      <!-- App Branding -->
      <!-- <a class="navbar-brand m-0" [routerLink]="['/']">
        <span class="brand-logo" style="margin-right: 4px;">
          <img
            class="dark-logo"
            style="margin-top: 16px; margin-bottom: 16px"
            src="assets/images/logo/logo-text.png"
            height="30"
            alt="brand-logo"
          />
          <img
            class="light-logo"
            style="margin-top: 16px; margin-bottom: 16px"
            src="assets/images/logo/white-logo-text.png"
            height="30"
            alt="brand-logo"
          />
        </span>
      </a> -->
      <!-- <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2> -->
    </li>

    <!-- Menu Toggler -->
    <!-- <li class="nav-item nav-toggle">
      <a
        class="nav-link modern-nav-toggle d-none d-xl-block pr-0"
        (click)="toggleSidebarCollapsible()"
      >
        <i
          [ngClass]="isCollapsed === true ? 'icon-circle' : 'icon-disc'"
          class="toggle-icon feather font-medium-4 collapse-toggle-icon text-primary"
        ></i>
      </a>
      <a
        class="nav-link modern-nav-toggle d-block d-xl-none pr-0"
        (click)="toggleSidebar()"
      >
        <i data-feather="x" class="font-medium-4 text-primary toggle-icon"></i>
      </a>
    </li> -->
  </ul>
</div>
<!--/ Menu header -->

<!-- Navbar shadow -->
<div class="shadow-bottom" [ngClass]="{ 'd-block': isScrolled }"></div>

@if (currentUser) {
  <!-- @if (currentUser.subscription) { -->
    <!-- Main menu -->
    <div class="main-menu-content bg-primary " (scroll)="onSidebarScroll()" ngxScrollbar style="background-image: url('assets/images/bg-pattern.svg'); z-index: 99; overflow-y: auto; height: 65% !important;">
      <ul class="navigation navigation-main bg-primary " layout="vertical" core-menu style="background-image: url('assets/images/bg-pattern.svg'); font-family: 'Almarai-Regular';">
      </ul>
    </div>
    <!--/ Main menu -->
  <!-- } -->
}

<div class="d-flex justify-content-end align-items-center flex-column" style="position: absolute; bottom: 0; right: 25px;">
  <img style="width: 50%;" src="assets/images/logo-white.svg" alt="">
  <p class="text-white mt-1">جميع الحقوق محفوظة لمنصة كسب</p>
</div>
