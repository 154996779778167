<div class="block-ui-template">

  <div class="row">

    <!-- Spinner -->
    <div class="col-12">

      <div class="spinner-border text-light" role="status">
        <span class="sr-only">
        </span>
      </div>

    </div>

    <!-- Text -->
    <strong class="col-12 mt-1">
      {{ appKeys.LOADING | translate }}
    </strong>

  </div>

</div>