import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FilePreviewService {

  // ------------------------------- Public Fields -------------------------------

  public previewFileChanged: BehaviorSubject<boolean>;
  public fileSrc: string = '';
  public previewFileRef: boolean = false;
  public isFilePreview: boolean = true;

  // ------------------------------- Private Fields -------------------------------


  // ------------------------------- Constructor -------------------------------

  constructor(

  ) {
    this.previewFileChanged = new BehaviorSubject(false);
  }

  // ------------------------------- interface Resolve -------------------------------


  // ------------------------------- Public Functions -------------------------------

  public previewFile(value: any): void {
    this.previewFileRef = value;
    this.previewFileChanged.next(this.previewFileRef);
  }

  // ------------------------------- Private Functions -------------------------------

}
