export class AlertComponentState {

  public title: string = '';

  public set setSuccessMessage(value: string) {
    this.successMessage = value;

    setTimeout(
      () => {
        this.successMessage = '';
      },
      3000,
    );
  }

  public set setWarningMessage(value: string) {
    this.warningMessage = value;

    setTimeout(
      () => {
        this.warningMessage = '';
      },
      3000,
    );
  }

  public get isError(): boolean {
    return this.errorMessage != '';
  }

  public successMessage: string = '';
  public warningMessage: string = '';
  public errorMessage: any = '';
  public noteMessage: string = '';

  public isVisible(): boolean {
    return this.successMessage != '' ||
      this.warningMessage != '' ||
      this.errorMessage != '' ||
      this.noteMessage != '';
  }


  // public successMessage: string = appArabic.data.OPERATION_SUCCEEDED;
  // public warningMessage: string = appArabic.data.OPERATION_FAILED;
  // public errorMessage: string = appArabic.data.OPERATION_FAILED;

  public setError(error: any): void {
    if (error && error.errorTitle) {
      this.title = error.errorTitle;
      this.errorMessage = error.errorMessage;
    }
    else {
      this.errorMessage = error;
    }
  }

  public clearError(): void {
    this.errorMessage = '';
  }

  public clearMessages(instant: boolean = true): void {
    if(instant) {
      this.successMessage = '';
      this.warningMessage = '';
      this.errorMessage = '';
      this.noteMessage = '';
    }
    else {
      setTimeout(
        () => {
          this.successMessage = '';
          this.warningMessage = '';
          this.errorMessage = '';
          this.noteMessage = '';
        }, 
        3000,
      );
    }
  }
}