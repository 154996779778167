import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CoreCommonModule } from '@core/common.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogsComponent } from './presentation/dialogs/dialogs.component';

@NgModule({
  declarations: [
    DialogsComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    NgbModule,
    TranslateModule.forChild(),
    FontAwesomeModule
  ],
  exports: [
    DialogsComponent,
  ],
})
export class DialogsModule { }
