import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: 'textarea[autoResize]'
})
export class AutoResizeTextareaDirective implements OnInit {
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.adjust();
  }

  @HostListener('input')
  onInput(): void {
    this.adjust();
  }

  private adjust(): void {
    const nativeElement = this.elementRef.nativeElement as HTMLTextAreaElement;
    nativeElement.style.overflow = 'hidden';
    nativeElement.style.height = 'auto';
    nativeElement.style.height = `${nativeElement.scrollHeight}px`;
  }
}