export const locale = {
  lang: 'ar',

  data: {
    SEND_MESSAGE: 'إرسال رسالة',
    SEND_TO: 'إرسال إلى',
    MESSAGE_TITLE: 'عنوان الرسالة',
    MESSAGE_CONTENT: 'محتوى الرسالة',

    MESSAGE_TITLE_REQUIRED: 'الرجاء إدخال عنوان الرسالة.',
    MESSAGE_CONTENT_REQUIRED: 'الرجاء إدخال محتوى الرسالة.',

    SELLER: 'تاجر',
    COMPANY: 'شركة',
  },
};
