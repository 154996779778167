import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
const SECRET_KEY = 'AF4BDFD01F1A1AE59495B06B56EBBE3022FE4C65DA030618F558625DC3C6C8B8';
const SecureStorage = require('secure-web-storage');

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() {
  }

  public setJsonValue(
    key: string, 
    value: any,
  ): void {
    this
      .secureStorage
      .setItem(
        key, 
        value,
      );
  }

  // Get the json value from local storage
  public getJsonValue(
    key: string,
  ): any {
    return this
      .secureStorage
      .getItem(
        key,
      );
  }

  // Get the json value from local storage
  public removeJsonValue(
    key: string,
  ): any {
    return this
      .secureStorage
      .removeItem(
        key,
      );
  }

  // Clear the local storage
  public clear(): any {
    return this.secureStorage.clear();
  }

  public secureStorage = 
    new SecureStorage(
      localStorage, 
      {
        hash: function hash(
          key: any,
        ): any {
          key = 
            CryptoJS
              .SHA256(
                key, 
                {
                  SECRET_KEY,
                }
              );
          
          return key.toString();
        },

        // Encrypt the localStorage data
        encrypt: function encrypt(
          data: any,
        ) {
          data = 
            CryptoJS
              .AES
              .encrypt(
                data, 
                SECRET_KEY,
              );

          data = 
            data
            .toString();
          
            return data;
        },
        // Decrypt the encrypted data
        decrypt: function decrypt(
          data: any,
        ) {
          data = 
            CryptoJS
              .AES
              .decrypt(
                data, 
                SECRET_KEY,
              );

          data = 
            data
              .toString(
                CryptoJS.enc.Utf8,
              );
            
          return data;
        }
      },
    );
}