<!-- Image Preview -->
<ng-container *ngIf="appService.isFilePreviewOpen === true">

  <div class="modal d-block" id="file-preview" style="top:0 !important; overflow-y: auto;" (click)="uiEvents.close()">

    <div class="modal-dialog" style="min-width: 75% !important;">
    
      <div class="modal-content p-0" (click)="uiEvents.onModalContentClick($event)">
    
        <div class="modal-header">
    
          <h5 class="modal-title"></h5>
    
          <div class="modal-actions">
            
            <a class="text-body" data-dismiss="modal" aria-label="Close" (click)="uiEvents.close()">
              <i [data-feather]="'x'"></i>
            </a>
          
          </div>
        
        </div>
        
        <div class="modal-body flex-grow-2 p-0">

          <ngx-extended-pdf-viewer [src]="mediaPreviewService.getProtectedUrl(uiState.fileSrc)" [useBrowserLocale]="true"></ngx-extended-pdf-viewer>

        </div>
      
      </div>
    
    </div>
  
  </div>

</ng-container>

<div class="modal-backdrop fade show" *ngIf="appService.isFilePreviewOpen === true"></div>
<!--/ Image Preview -->
