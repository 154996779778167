import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilePreviewComponent } from 'app/components/file-preview/presentation/file-preview/file-preview.component';

import { CoreCommonModule } from '@core/common.module';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

@NgModule({
  declarations: [
    FilePreviewComponent,
  ],
  imports: [
    CommonModule,
    CoreCommonModule,
    NgxExtendedPdfViewerModule,
  ],
  exports: [
    FilePreviewComponent,
  ],
})
export class FilePreviewModule { }
