import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {
  transform(days: number): string {
    const years = Math.floor(days / 365);
    const months = Math.floor(days / 30);
    const weeks = Math.floor(days / 7);

    if (years >= 1 && days % 365 === 0) {
      return `${years} ${years === 1 ? '(سنة)' : '(سنة)'}`;
    } else if (months >= 1 && days % 30 === 0) {
      return `${months} ${months === 1 ? '(شهر)' : '(شهر)'}`;
    } else if (weeks >= 1 && days % 7 === 0) {
      return `${weeks} ${weeks === 1 ? '(أسبوع)' : '(أسبوع)'}`;
    } else {
      return `${days} ${days === 1 ? '(يوم)' : '(يوم)'}`;
    }
  }
}