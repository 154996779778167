import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService implements Resolve<any> {

  // ------------------------------- Public Fields -------------------------------

  public isMetaDataReady: boolean = false;
  public isMediaPreviewOpen: boolean = false;
  public isFilePreviewOpen: boolean = false;
  public isQRCodePreviewOpen: boolean = false;

  // ------------------------------- Private Fields -------------------------------

  private _routeParams: any;

  // ------------------------------- Constructor -------------------------------

  constructor(
    private _httpClient: HttpClient,
  ) {
  }

  // ------------------------------- interface Resolve -------------------------------

  resolve(
    route: ActivatedRouteSnapshot,
  ): Observable<any> | Promise<any> | any {
    this._routeParams = route.params;
    return new Promise<void>((resolve, reject) => {
      Promise.all([
      ]).then(() => {
        resolve();
      },
        reject,
      );
    });
  }

  // ------------------------------- Public Functions -------------------------------

  // ------------------------------- Private Functions -------------------------------

}
