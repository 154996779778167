import { Component, OnInit, Input } from '@angular/core';
import { CoreConfigService } from '@core/services/config.service';
import { takeUntil } from 'rxjs/operators';

// Breadcrumb component interface
export interface Breadcrumb {
  type?: string;
  alignment?: string;
  links?: Array<{
    name: string;
    isLink: boolean;
    link?: string;
  }>;
}

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html'
})
export class BreadcrumbComponent implements OnInit {
  // input variable
  @Input() breadcrumb!: Breadcrumb;

  public currentSkin: string = '';

    constructor(
      private _coreConfigService: CoreConfigService,
    ) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    // concatenate default properties with passed properties
    this.breadcrumb = this.breadcrumb;

    this._coreConfigService
      .getConfig()
      .subscribe((config) => {
        this.currentSkin = config.layout.skin;
      });
  }
}
