import { environment } from "../../../environments/environment";

export const appConstants = {
  // ------------------------------- Values -------------------------------

  toastrConfig: {
    toastClass: "toast ngx-toastr",
    positionClass: "toast-top-right",
    closeButton: true,
    progressBar: true,
    tapToDismiss: true,
    newestOnTop: true,
  },
  flatpickrOptions: {
    altInput: true,
    enableTime: true,
    defaultDate: new Date(),
    // altFormat: environment.altDateFormat,
    // enableSeconds: true,
  },
  flatpickrOptionsNoDefaultDate: {
    altInput: true,
    altFormat: environment.altDateFormat,
    // enableTime: true,
    // enableSeconds: true,
  },
  pageSizes: [15, 25, 50, 75, 100, 500],
  notDeleted: 'NOT_DELETED',
  roles: {
    TEACHER: "teacher",
    STUDENT: "student",
    STAFF: "staff",
    ACCOUNTER: "accounter",
  },
  defaultPageSize: 25,
  notFoundMessage: 'No data found',
  defaultCountryCode: '+963',
  operations: {
    equal: 'eq',
    greaterThan: 'gt',
    greaterThanEqual: 'gte',
    lessThan: 'lt',
    lessThanEqual: 'lte',
  },

  // ------------------------------- Dictionaries -------------------------------

  appFeatures: {
    ALL_SYSTEM_FEATURE: "كامل البرنامج",
  },
  contatInfoTypes: {
    Facebook: "فيس بوك",
    Twitter: "تويتر",
    Whatsapp: "وتس أب",
    Instagram: "إنستجرام",
    LinkedIn: "LinkedIn",
    Phone: "هاتف",
    Fax: "فاكس",
    Website: "الموقع الإلكتروني",
    Email: "الإيميل",
    Youtube: "يوتيوب",
  },
};
