export const locale = {
    lang: 'ar',
    data: {
        MISCELLANEOUS: {
            HOME: 'الصفحة الرئيسية',
            BRAND_LOGO: 'شعار الشركة',
        },
        ERROR_PAGE:{
            TITLE: 'حدث خطأ ما!',
            TEXT: 'عذراً! الصفحة التي طلبتها غير موجودة.',
            ERROR_PAGE_IMAGE_ALT: 'صفحة حدث خطأ',
        },
        NOT_AUTHORIZED: {
            TITLE: 'غير مسموح',
            TEXT: 'ليس لديك إذن للوصل إلى هذه الصفحة.',
            NOT_AUTHORIZED_PAGE: 'صفحة غير مسموح لك'
        }
    }
}