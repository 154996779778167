import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { AuthService } from 'app/features/auth/@core/utils/auth.service';
import { appConstants } from 'app/@core/values/app-constants';
import { appRoutesConstants } from 'app/@core/values/app-routes-constants';
import { Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  /**
   *
   * @param {AuthService} _authService
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
  ) { }

  /**
   * Add auth header with jwt if user is logged in and request is to api url
   * @param request
   * @param next
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const currentUser = this._authService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.token;

    const isApiUrl = request.url.startsWith(environment.apiUrl);

    if (isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: isLoggedIn ? `Bearer ${currentUser.token}` : ''
        },
        // params: (request.params ? request.params : new HttpParams()).set('culture', appConstants.appCulture)
      });
    }

    if(currentUser) {
      if(currentUser.token) {
        // const tokenParts = currentUser.token.split('|');
        // if (tokenParts.length !== 2) {
        //   // Token format is invalid
        // }
        // else {
        //   const expirationTimestamp = parseInt(tokenParts[0]);
        //   const currentTimestamp = Math.floor(Date.now() / 1000);
          
        //   if(expirationTimestamp < currentTimestamp) {
        //     this._authService.logout();
        //     this._router.navigate([appRoutesConstants.login]);
        //   }
        // }
        // const decodedToken = JSON.parse(atob(currentUser.token.split('.')[1]));
        // const expirationTime = decodedToken.exp * 1000; // Convert to milliseconds
      
        // if(Date.now() >= expirationTime) {
        //   this._authService.logout();
        //   this._router.navigate([appRoutesConstants.login]);
        // }
      }
    }
    
    return next.handle(request);
  }
}
