<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">

  <!-- Navbar brand -->
  <ul class="nav navbar-nav flex-row">

    <li class="nav-item">
    
      <a class="navbar-brand" [routerLink]="['/']">
    
        <span class="brand-logo">
    
          <img src="{{ coreConfig.app.appLogoImage }}" alt="{{ layoutKeys.LOGOALT | translate }}" width="36" />
    
        </span>
    
        <h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
    
      </a>
    
    </li>
  
  </ul>
  <!--/ Navbar brand -->

</div>

<div class="navbar-container d-flex content">

  <div class="bookmark-wrapper d-flex align-items-center">
  
    <!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
    <ul class="nav navbar-nav" *ngIf="!coreConfig.layout.menu.hidden">
  
      <li class="nav-item d-xl-none">
  
        <a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
  
          <span [data-feather]="'menu'" [class]="'ficon'"></span>
  
        </a>
  
      </li>
  
    <!-- Search -->
    <!-- <app-navbar-search></app-navbar-search> -->
    <!--/ Search -->
    
    </ul>
    <!--/ Menu Toggler -->

  </div>

  <ul class="nav navbar-nav align-items-center ml-auto">

    <!-- Toggle skin -->
    <li class="nav-item d-none d-lg-block">

      
      <a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
      
        <span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
      
      </a>
    
    </li>
    <!--/ Toggle skin -->

    <!-- User Dropdown -->
    <li ngbDropdown class="nav-item dropdown-user">
      
      <a id="dropdown-user" class="nav-link dropdown-toggle dropdown-user-link" ngbDropdownToggle
        aria-haspopup="true" aria-expanded="false">

        <ng-container *ngIf="this.currentUser">

          <div class="user-nav d-sm-flex d-none">

            <span class="user-name font-weight-bolder">{{ this.currentUser.nickName }}</span>

            <span class="user-status">{{ this.currentUser.roles }}</span>

          </div>

          <span class="avatar">
            
            <img class="round" [src]="avatarImage"
              alt="{{ layoutKeys.PROFILE_LOGO_ALT | translate }}" height="40" width="40" *ngIf="avatarImage" />

              <span class="avatar-content" *ngIf="!avatarImage">{{this.currentUser.nickName | initials}}</span>
              
              <span class="avatar-status-online"></span>

          </span>

        </ng-container>

      </a>

      <div ngbDropdownMenu aria-labelledby="navbar  UserDropdown" class="dropdown-menu dropdown-menu-right">

        <div ngbDropdownItem [routerLink]="[appRoutesConst.accountSettings]">
          <span [data-feather]="'settings'" [class]="'mr-50'"></span> 
            {{ layoutKeys.ACCOUNT_SETTINGS | translate }}
        </div>

          <div ngbDropdownItem (click)="logout()">
            <span [data-feather]="'power'" [class]="'mr-50'"></span>
            {{ layoutKeys.LOGOUT | translate }}
          </div>

      </div>

    </li>
    <!--/ User Dropdown -->

  </ul>

</div>