import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { QRCodePreviewState } from './ui/qr-code-preview.state';
import { QRCodePreviewEvents } from './ui/qr-code-preview.events';
import { QRCodePreviewService } from '../../@core/utils/qr-code-preview.service';
import { AppService } from 'app/app.service';
import { HttpHeaders } from '@angular/common/http';
import { MediaPreviewService } from '../../../media-preview/@core/utils/media-preview.service';
import { AuthService } from 'app/features/auth/@core/utils/auth.service';

@Component({
  selector: 'app-qr-code-preview',
  templateUrl: './qr-code-preview.component.html',
  styleUrls: ['./qr-code-preview.component.scss']
})
export class QRCodePreviewComponent implements OnInit, OnDestroy {

  // ------------------------------- Public Fields -------------------------------
  
  public uiState: QRCodePreviewState = new QRCodePreviewState();  

  public uiEvents: QRCodePreviewEvents = new QRCodePreviewEvents(
    this.uiState,
    this.appService,
    this._QRCodePreviewService,
    );

  // ------------------------------- Private Fields -------------------------------


  // ------------------------------- Constructor -------------------------------

  constructor(
    private _QRCodePreviewService: QRCodePreviewService,
    private _authService: AuthService,
    public appService: AppService,
    public mediaPreviewService: MediaPreviewService
  ) {
    this.uiState._unsubscribeAll = new Subject();
  }

  // ------------------------------- Lifecycle Hooks -------------------------------

  ngOnInit(): void {
    this
      ._QRCodePreviewService
      .previewQRCodeChanged
      .pipe(
        takeUntil(
          this.uiState._unsubscribeAll,
        ),
      ).subscribe(
        response => {
          this
            .uiState
            .value = 
            this
            ._QRCodePreviewService
            .value;

          this
            .uiEvents
            .toggleOpen(
              response,
            );
    });
  }

  ngOnDestroy(): void{
    // Unsubscribe from all subscriptions
    this.uiState._unsubscribeAll.next(null);
    this.uiState._unsubscribeAll.complete();
  }

  // ------------------------------- Public Functions -------------------------------
  

  // ------------------------------- Private Functions -------------------------------
  
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.appService.isQRCodePreviewOpen){
      event.preventDefault();
      this.uiEvents.toggleOpen(false);
      this._QRCodePreviewService.previewQRCodeChanged.next(false);
    }
  }
}
