import { Directive, HostListener, ElementRef, HostBinding } from '@angular/core';
import numeral from 'numeral';


@Directive({
    selector: '[appThousandsSeparator]'
})
export class ThousandsSeparatorDirective {

    @HostBinding('value') value: string = '';

    @HostListener('input', ['$event.target.value'])
    onInputChange(value: string) {
        // Remove all commas from the input string
        value = value.replace(/,/g, '');

        // Add commas back in every 3 digits
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

        // Update the input value with the formatted string
        this.value = value;
    }
}

