import { NgModule } from '@angular/core';
import { AppUtilsService } from './app-utils.service';
import { DateUtilsService } from './date-utils.service';

@NgModule({
  declarations: [
  ],
  imports: [
  ],
  providers: [
    AppUtilsService,
    DateUtilsService,
  ],
  bootstrap: []
})
export class AppUtilsModule { }
