import { NgModule } from '@angular/core';

import { HighlightSearchTextPipe } from './highlight-search-text.pipe';
import { PreserveFormattingPipe } from './preserve-formatting.pipe';
import { SecondsToTimePipe } from './seconds-to-time.pipe';
import { UtcToLocalDateTimePipe } from './utc-to-local-date-time.pipe';
import { InitialsPipe } from './initials.pipe';
import { FilterPipe } from './filter.pipe';
import { StripHtmlPipe } from './stripHtml.pipe';
import { SafePipe } from './safe.pipe';
import { DurationPipe } from './duration.pipe';
import { LineBreakPipe } from './line-break.pipe';
import { DurationDaysPipe } from './duration-days.pipe';

@NgModule({
  declarations: [
    InitialsPipe, 
    FilterPipe, 
    StripHtmlPipe, 
    SafePipe, 
    HighlightSearchTextPipe, 
    PreserveFormattingPipe, 
    SecondsToTimePipe, 
    UtcToLocalDateTimePipe, 
    DurationPipe,
    LineBreakPipe,
    DurationDaysPipe,
  ],
  imports: [],
  exports: [
    InitialsPipe, 
    FilterPipe, 
    StripHtmlPipe, 
    SafePipe, 
    HighlightSearchTextPipe, 
    PreserveFormattingPipe, 
    SecondsToTimePipe, 
    UtcToLocalDateTimePipe, 
    DurationPipe,
    LineBreakPipe,
    DurationDaysPipe,
  ]
})
export class CorePipesModule {}
