import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaPreviewState } from './ui/media-preview.state';
import { MediaPreviewEvents } from './ui/media-preview.events';
import { MediaPreviewService } from '../../@core/utils/media-preview.service';
import { AppService } from 'app/app.service';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent implements OnInit, OnDestroy {

  // ------------------------------- Public Fields -------------------------------
  
  public uiState: MediaPreviewState = new MediaPreviewState();  

  public uiEvents: MediaPreviewEvents = new MediaPreviewEvents(
    this.uiState,
    this.appService,
    this.mediaPreviewService,
    );

  // ------------------------------- Private Fields -------------------------------

  // ------------------------------- Constructor -------------------------------

  constructor(
    public mediaPreviewService: MediaPreviewService,
    public appService: AppService,
    ) {
    this.uiState._unsubscribeAll = new Subject();
  }

  // ------------------------------- Lifecycle Hooks -------------------------------

  ngOnInit(): void {
    // Subscribe to Compose Model Changes
    this
      .mediaPreviewService
      .previewImageChanged
      .pipe(
        takeUntil(
          this.uiState._unsubscribeAll,
        ),
      ).subscribe(
        response => {
          this
            .uiEvents
            .toggleOpen(
              response,
            );

          this
            .uiState
            .mediaSrc = 
            this
            .mediaPreviewService
            .mediaSrc;

          this
            .uiState
            .isImagePreview = 
            this
            .mediaPreviewService
            .isImagePreview;
    });
  }

  ngOnDestroy(): void{
    // Unsubscribe from all subscriptions
    this.uiState._unsubscribeAll.next(null);
    this.uiState._unsubscribeAll.complete();
  }

  // ------------------------------- Public Functions -------------------------------
  
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if(this.appService.isMediaPreviewOpen){
      event.preventDefault();
      this.uiEvents.toggleOpen(false);
      this.mediaPreviewService.previewImageChanged.next(false);

    }
  }

  // ------------------------------- Private Functions -------------------------------
  
}
