import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { appRoutesConstants } from 'app/@core/values/app-routes-constants';
import { appConstants } from 'app/@core/values/app-constants';
import { AuthService } from 'app/features/auth/@core/utils/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  /**
   * @param {Router} _router
   * @param {AuthService} _authService
   */
  constructor(private _router: Router,
    private _authService: AuthService,
    private _toastrService: ToastrService,
    private _translate: TranslateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(
        err => {
          if ([401, 403].indexOf(err.status) !== -1) {
            // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
            this._router.navigate([
              appRoutesConstants.notAuthorized,
            ]);

            // ? Can also logout and reload if needed
            // this._authService.logout();
            // location.reload(true);
          }
          else if ([500].indexOf(err.status) !== -1) { // show message for 500 server error
            let errorTitle: string = this._translate.instant('ERROR_INTERCEPTOR.ERROR500_TITLE');
            let errorMessage: string = this._translate.instant('ERROR_INTERCEPTOR.ERROR500_MESSAGE');
            return throwError(
              {
                errorTitle: errorTitle,
                errorMessage: errorMessage,
              },
            );
          }

          // throwError
          const error = err.error.message || err.statusText;

          // For Unknow Error, almost connection problem
          if (error && error.toLowerCase() == 'unknown error') {
            let errorTitle: string = this._translate.instant('ERROR_INTERCEPTOR.UKNOWN_ERROR_TITLE');
            let errorMessage: string = this._translate.instant('ERROR_INTERCEPTOR.UKNOWN_ERROR_MESSAGE');
            return throwError(
              {
                errorTitle: errorTitle,
                errorMessage: errorMessage,
              },
            );
          }
          return throwError(error);
        },
      ),
    );
  }
}
