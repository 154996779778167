<ng-template #modalElement let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="myModalLabel160">
      {{ uiState.title }}
    </h5>

    <button
      type="button"
      class="close"
      (click)="uiEvents.close(false)"
      aria-label="Close"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form (submit)="uiEvents.close(true)">
    <div
      class="modal-body p-1"
      style="padding-bottom: 7px"
      tabindex="0"
      ngbAutofocus
    >
      {{ uiState.message }}

    <div class="modal-footer pr-0 pb-0" style="margin-top: 7px;">
      <button
        type="submit"
        [class]="uiState.buttonClassType"
        class="btn m-0"
        (click)="uiEvents.close(true)"
        rippleEffect
        id="submit"
        #submit
        *ngIf="uiState.primaryButtonText"
      >
        {{ uiState.primaryButtonText }}
      </button>
    </div>
    </div>
  </form>
</ng-template>
