import { AppService } from "app/app.service";
import { FilePreviewState } from "./file-preview.state";
import { FilePreviewService } from "../../../@core/utils/file-preview.service";


export class FilePreviewEvents {

  // ------------------------------- Public Fields -------------------------------


  // ------------------------------- Private Fields -------------------------------


  // ------------------------------- Constructor -------------------------------

  constructor(
    private _uiState: FilePreviewState,
    private _appService: AppService,
    private _filePreviewService: FilePreviewService,
  ) {
  }

  // ------------------------------- Public Functions -------------------------------


  public toggleOpen(
    isOpen: boolean,
  ): void {
    if (isOpen) {
      this._appService.isFilePreviewOpen = true;
    }
    else {
      this._appService.isFilePreviewOpen = false;
    }
  }

  public close(): void {
    this
      ._appService
      .isFilePreviewOpen = false;

    this
      ._filePreviewService
      .previewFileChanged
      .next(
        false,
      );
  }

  public onModalContentClick(
    event: any,
  ): void {
    event.stopPropagation();
  }

  // ------------------------------- Private Functions -------------------------------



}

