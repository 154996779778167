export const locale = {
  lang: 'ar',

  data: {
    ADD_PRODUCT: 'إضافة منتج جديد',
    PRODUCT_INFO: 'معلومات المنتج',
    PRODUCT_NAME: 'اسم المنتج',
    PRODUCT_CATEGORY: 'تصنيف المنتج',
    PRODUCT_MODEL: 'رقم الموديل',
    PRODUCT_PRICE: 'سعر المنتج',
    COLORS: 'الألوان المتوفرة',
    OPTIONS: 'ميزات إضافية',
    OPTION_KEY: 'اسم الميزة',
    OPTION_VALUE: 'الوصف',
    DELIVER_TIME: 'وقت التوصيل',
    PRODUCT_DESCRIPTION: 'وصف المنتج',
    PRODUCT_DETAILS: 'تفاصيل المنتج',
    PRODUCT_DISCOUNT: 'الحسم',

    COMPANY_PRODUCTS: 'منتجات الشركة',

    COLORS_IMAGE_REQUIRED: 'الرجاء إدخال صورة لكل لون تم إدخاله.',
    OPTIONS_REQUIRED: 'الرجاء إكمال معلومات الميزات الإضافية.',
    DESCRIPTION_REQUIRED: 'الرجاء إدخال الوصف.',
    DISCOUNT_REQUIRED: 'الرجاء إدخال الحسم.',
    PRICE_REQUIRED: 'الرجاء إدخال السعر.',
    DELIVERY_TIME_REQUIRED: 'الرجاء إدخال وقت التوصيل.',
    CATEGORY_REQUIRED: 'الرجاء إدخال الصنف.',
    MODEL_REQUIRED: 'الرجاء إدخال الموديل.',
  },
};
