import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(fullName: string): any {
    const initials = fullName
      ?.split(' ')
      .map(n => n[0])
      .join('');

    return initials?.substr(0, 2); // Limit to maximum two characters
  }
}
