import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'utcToLocalDateTime'
})
export class UtcToLocalDateTimePipe implements PipeTransform {
  transform(
    utcDate: string,
    formate: UtcToLocalDateTimeFormate | string
  ): string {
    var browserLanguage = navigator.language;

    if(formate == UtcToLocalDateTimeFormate.SHORT) {
      let date = new Date(utcDate).toLocaleDateString(browserLanguage);
      let time = new Date(utcDate).toLocaleTimeString(browserLanguage);

      return `${date}, ${time}`;
    }
    else if(formate == UtcToLocalDateTimeFormate.SHORT_DATE) {
      return new Date(
        utcDate,
      )
      .toLocaleDateString(
        browserLanguage,
      );
    }
    else if(formate == UtcToLocalDateTimeFormate.SHORT_TIME) {
      return new Date(
        utcDate,
      )
      .toLocaleTimeString(
        browserLanguage,
      );
    }
    else if(formate == UtcToLocalDateTimeFormate.FULL) {
      return new Date(
        utcDate,
      )
      .toString();
    }
    else {
      console.error(
        `Do not have the logic to formate utc date, formate:${formate}`
      );
      return new Date(
        utcDate,
      )
      .toString();
    }
  }
}

export enum UtcToLocalDateTimeFormate {
  FULL = 'full', // EEEE, MMMM d, y, h:mm:ss a zzzz
  SHORT = 'short', // d/M/yy, h/mm
  SHORT_DATE = 'shortDate', // d/M/yy
  SHORT_TIME = 'shortTime', // h:mm
}