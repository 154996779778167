import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NavbarSearchData } from "./navbar-search.data";

export class NavbarSearchEvents {

    // ------------------------------- Public Fields -------------------------------


    // ------------------------------- Private Fields -------------------------------


    // ------------------------------- Constructor -------------------------------

    constructor(
    ) {

    }

    // ------------------------------- Public Functions ------------------------------- 

    public onSearch(event: any): void {
        const searchText: string = event.target.value.toLowerCase();


    }

    // ------------------------------- Private Functions -------------------------------

}
