<ngb-alert *ngIf="uiState.successMessage != '' && uiState.successMessage" [type]="'success'" [dismissible]="true"
    (closed)="uiState.successMessage = ''">

    <!-- <h4 class="alert-heading">
        {{ uiState.title != '' ? uiState.title : appKeys.SUCCESS | translate }}
    </h4> -->

    <div class="alert-body">{{ uiState.successMessage | translate }}</div>

</ngb-alert>

<ngb-alert *ngIf="uiState.warningMessage != '' && uiState.warningMessage" [type]="'warning'" [dismissible]="false"
    (closed)="uiState.warningMessage = ''">

    <h4 class="alert-heading">
        {{ uiState.title != '' ? uiState.title : appKeys.WARNING | translate }}
    </h4>

    <div class="alert-body">{{ uiState.warningMessage | translate }}</div>

</ngb-alert>

<ngb-alert *ngIf="uiState.errorMessage != '' && uiState.errorMessage" [type]="'danger'" [dismissible]="false">
    <!-- [dismissible]="true" (closed)="uiState.errorMessage = ''" -->

    <h4 class="alert-heading">
        {{ uiState.title != '' ? uiState.title : appKeys.ERROR | translate }}
    </h4>

    <div class="alert-body">{{ uiState.errorMessage | translate }}</div>

</ngb-alert>

<ngb-alert *ngIf="uiState.noteMessage" [type]="'primary'" [dismissible]="false">

    <div class="alert-body">{{ uiState.noteMessage | translate }}</div>

</ngb-alert>