import { TranslateService } from "@ngx-translate/core";
import { DialogsComponentState } from "./dialogs.state";

export class DialogsEvents {
  // ------------------------------- Public Fields -------------------------------

  // ------------------------------- Private Fields -------------------------------

  // ------------------------------- Constructor -------------------------------

  constructor(
    private _uiState: DialogsComponentState,
    private _translate: TranslateService,
  ) {}

  // ------------------------------- Public Functions -------------------------------

  public close(
    result: any,
  ): void {
    this
      ._uiState
      .modalRef
      .close(
        result,
      );
  }
  
  // ------------------------------- Private Functions -------------------------------
}
