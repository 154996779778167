import { Component, OnDestroy, OnInit, ViewEncapsulation, ElementRef, HostListener } from '@angular/core';

import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { CoreConfigService } from '@core/services/config.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Console } from 'console';

@Component({
  selector: 'vertical-layout',
  templateUrl: './vertical-layout.component.html',
  styleUrls: ['./vertical-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VerticalLayoutComponent implements OnInit, OnDestroy {
  coreConfig: any;
  screenHeight: number = 1000;
  inAuth: boolean = false;

  // Private
  private _unsubscribeAll: Subject<any>;

  /**
   * Constructor
   *
   * @param {CoreConfigService} _coreConfigService
   */
  constructor(
    private _coreConfigService: CoreConfigService, 
    private _elementRef: ElementRef,
    private _route: ActivatedRoute,
    private _router: Router,
  ) {
    // Set the private defaults
    this.screenHeight = window.innerHeight;
    this._unsubscribeAll = new Subject();
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    setTimeout(
      () => {
        this.screenHeight = window.innerHeight;
      }, 
      1,
    );
  }

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config: any) => {
      this.coreConfig = config;
    });

    this._router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        let currentRoute = event.urlAfterRedirects.split("/")[1];
        if(currentRoute == 'auth' || currentRoute == 'miscellaneous') {
          this.inAuth = true;
        } 
        else {
          this.inAuth = false;
        }
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void{
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }
}
