export const locale = {
  lang: 'ar',

  data: {
    ADD_AGENT: 'إضافة وكيل',
    AGENT_INFO: 'معلومات الوكيل',
    AGENT_NAME: 'اسم الوكيل',
    STARTING_DATE_OF_AGENCY: 'تاريخ بداية التوكيل',
    NUMBER_OF_SOLD_ORDERS: 'عدد الطلبات',
    NUMBER_OF_PRODUCTS_RESPONSIBLE_FOR: 'عدد المنتجات الموكل بها',
    AGENT_STATUS: 'حالة الوكيل',
    AGENT_DETAILS: 'تفاصيل الوكيل',
  },
};
